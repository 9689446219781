import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../../components/Layout";
import Seo from "../../components/seo";
import { BookNowButton } from "../../components/FoldBar";
import allison from "../../../static/img/fb_t_allison.png";
import barbara from "../../../static/img/fb_t_barbara.png";
import leah from "../../../static/img/fb_t_leah.png";
import lizzie from "../../../static/img/fb_t_lizzie.png";
import * as ROUTES from "../../constants/routes";

const DESCRIPTION = `Are you ready to shed those pounds for good!? Then these 1-on-1 progressive programs are for YOU! A ONE TIME PURCHASE of 6-or 12-weeks to build healthy habits so you can lose the weight and KEEP IT OFF! You'll receive weekly meetings and comprehensive tools to create a #BALANCED lifestyle that you can stick to! We'll kick those pounds to the curb for good, and you'll NEVER have to start over on Monday again!`;

function Testimonial({
  children,
  name,
  image,
  reverse = false,
  showSeparator = true,
}) {
  return (
    <div
      className="forever-balance-testimonial"
      style={{
        position: "relative",
        flexDirection: "column",
      }}
    >
      <div className={`testimonial-main${reverse ? " reverse" : ""}`}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div className="container">
            <div className="quotations">&quot;</div>
            <div>{children}</div>
          </div>
          <div
            style={{
              paddingBottom: "20px",
              marginTop: "-20px",
              alignSelf: "flex-end",
              marginRight: "25%",
            }}
          >
            <span className="name">- {name} -</span>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img src={image} alt={`Testimonial from ${name}`} />
        </div>
      </div>
      {showSeparator && (
        <div style={{ backgroundColor: "#CD4F5F", height: "2px" }} />
      )}
    </div>
  );
}

function ForeverBalance() {
  return (
    <div className="balance-boot-camp">
      <Seo title="Forever Balanced" description={DESCRIPTION} />
      <h1 className="header-title header has-text-centered is-size-1 has-text-weight-bold">
        Find Your Forever #BALANCE
      </h1>

      <div className="intro">
        <div className="left has-text-centered">
          <p className="has-text-weight-semibold">
            ...you've tried EVERY diet
            <br />
            <br />
            ...you've bought EVERY supplement
            <br />
            <br />
            ...you have ALL the gym equipment
            <br />
            <br />
            ...and you know what a healthy food choice is.
            <br />
            <br />
            But you just can't seem to take the weight off no matter how hard
            you try!
          </p>
          <br />
          <br />

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div className="arrows">»&nbsp;</div>
            <BookNowButton
              showIcon={false}
              title={
                <span>
                  That's Me!
                  <br />
                  I'm Ready to Chat!
                </span>
              }
              href={ROUTES.CALENDLY_FOREVER_BALANCE_CONSULTATION}
              styleName={["pink", "normal-case"]}
            />
            <div className="arrows">&nbsp;«</div>
          </div>
        </div>
        <div className="right">
          <StaticImage
            src={"../../../static/img/kristie_watermelon.png"}
            alt="Kristie with a bowl of watermelon and a piece on her fork"
            loading="lazy"
          />
        </div>
      </div>

      <div className="footer-message" style={{ marginBottom: "3rem" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div
            style={{ height: "2px", backgroundColor: "black", width: "25%" }}
          />
        </div>
        <div>
          <p
            className="accented-text has-text-weight-bold has-text-centered"
            style={{
              padding: "2rem",
              fontSize: 34,
            }}
          >
            Don't worry I've got your back!
          </p>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div
            style={{ height: "2px", backgroundColor: "black", width: "25%" }}
          />
        </div>
      </div>

      <div className="low-down fb-included">
        <div className="item">
          <div className="item-title">
            <StaticImage
              src={"../../../static/img/fb-whats-included.png"}
              alt="Image detailing what's included in the Forever Balance package. Weekly 1:1 Zoom Meetings, Nutrition Education, Mindset Enhancement, Grocery lists, Workout Schedules, Progress Tracking, Comprehensive Resources, Unlimited Email Support, Tools to Continue Progress"
              loading="lazy"
              style={{ marginBottom: "1rem" }}
              height={800}
            />
          </div>

          <div
            className="item-description has-text-weight-semibold fb-included has-text-centered"
            style={{ alignSelf: "center" }}
          >
            With my Forever #BALANCE program, you'll build new healthy habits
            without any restriction or deprivation! The program will progress
            each week so that you never feel overwhelmed You'll gain knowledge
            and build confidence and before you know it, you'll have the healthy
            lifestyle you've always dreamed of! You'll never have to start over
            on Monday again!
            <div
              style={{
                marginTop: "1rem",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div className="arrows">»&nbsp;</div>
              <BookNowButton
                showIcon={false}
                title="Let's Do This!"
                href={ROUTES.CALENDLY_FOREVER_BALANCE_CONSULTATION}
                styleName={["pink", "normal-case"]}
              />
              <div className="arrows">&nbsp;«</div>
            </div>
          </div>
        </div>
      </div>

      <div className="dark-bg responsive-row">
        <div className="left fb-banner">
          <StaticImage
            src={"../../../static/img/kristie_w_arnold.png"}
            alt="Kristie with her bulldog, Arnold, trying to lick her face."
            loading="lazy"
            width={640}
            height={400}
          />
        </div>
        <div className="right fb-banner">
          <div className="fb-banner-text">
            "There's no better day
            <br />
            than TODAY to start
            <br />
            feeling your best."
          </div>
        </div>
      </div>

      <div className="forever-balance table has-text-centered">
        <StaticImage
          src={"../../../static/img/forever_balance_table.png"}
          alt="Table comparing the 6 and 12 week Forever Balance plans"
          loading="lazy"
        />
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Testimonial name="Barbara" image={barbara} reverse={true}>
          If you are thinking about joining the 6 week or 12 week Forever
          Balanced program take it from me…do it! I joined the 12 week program
          and I am so much happier and healthier! One of the best parts is you
          do not have to count calories and you are not given a list of food you
          can't eat. The other best part is your weekly meetings with Kristie.
          She is so knowledgeable and helps you with understanding your
          relationship with food. She encourages you and helps you see yourself
          as capable, healthy, and strong. Before joining the program, I had
          been consistent with the workouts for a few months. The program really
          put me over the edge...in the first 6 weeks I lost 12.5 inches. Thanks
          to Kristie, I am on a forever healthy journey!
        </Testimonial>

        <Testimonial name="Allison" image={allison}>
          All of the 'small' changes I have made have become part of my day to
          day so it doesn't feel like I'm doing anything extra! Meanwhile, the
          weight is just falling off!
        </Testimonial>

        <Testimonial name="Lizzy" image={lizzie} reverse={true}>
          <span>
            After my 12 weeks, I am so incredibly happy and encouraged by my
            results from Kristie's Forever Balanced Program. And they really
            aren't even results because I'm not done! I'm going to keep losing
            inches, continue with my new habits and grow healthier every day. I
            have never felt more confident and equipped to keep up this type of
            healthy, balanced lifestyle, and I've never been able to maintain
            this kind of consistency. As the program has come to an end, I have
            kept it all up on my own.
            <br />
            <br />
            One of my favorite things about Kristie's program is that she works
            on more than just food and exercise. Kristie has helped me in areas
            of my life I didn't even know needed work! She has given me tips and
            tools to stay more organized and BALANCED with my time, my sleep, my
            food, my life! Everything contributes to being more healthy and
            happy! Thank you Kristie for always encouraging and challenging me!!
          </span>
        </Testimonial>
        <Testimonial name="Leah" image={leah} showSeparator={false}>
          <span>
            After my 12 week forever balanced program, I now feel confident in
            what I have learned about myself and food. I have lost inches and
            weight but more than that I have lost the hold my emotions had over
            me. I now realize that this is a journey and not a destination! I am
            stronger, healthier and downright happier!
          </span>
        </Testimonial>
      </div>

      <div className="dark-bg">
        <div className="white-bg bio fb">
          <div className="left">
            <StaticImage
              src="../../../static/img/boot_camp_cut.png"
              loading="lazy"
              alt="Kristie ecstatic in a gym setting"
            />
          </div>

          <div className="right">
            <h4 className="has-text-centered is-size-3">
              Hey ladies! It's Kristie! AKA "Cheese" 😜{" "}
            </h4>
            <p>
              The entirety of my 20's was spent living a completely unbalanced
              lifestyle. I was super ambitious and put everything I had into
              being successful! Whether it was owning my own fitness studio,
              competing in natural bodybuilding, running the most successful
              franchise gym location in the country, or owning a meal prep
              restaurant (Version #2), I would push myself so hard that I would
              get to a point that I just couldn't push anymore! I'd then find
              myself on the opposite end of unhealthy, fighting depressive
              feelings and completely neglecting my health and career goals
              (Version 1). I lived a totally "black and white" life!
            </p>
            <p>
              But now, my lifestyle revolves around living in the GRAY! I preach
              #BALANCE! I scream it! Heck, I even got the word tattooed on the
              back of my neck! I've tossed extremes to the curb and give myself
              constant grace. I strive for #BALANCE mentally, emotionally and
              physically, and I'm so excited to help you do the same! No more
              yo-yo's. No more depriving and binging. No more starting over on
              Monday!
            </p>
          </div>
        </div>
      </div>

      <div className="content-wrapper" style={{ alignItems: "center" }}>
        <h4
          className="has-text-centered is-size-3 has-text-weight-bold"
          style={{ marginTop: 20 }}
        >
          Let's Meet to See if Forever #BALANCE is Right for YOU!
        </h4>
        <div className="forever-balance plans">
          <div
            className="twelve-week plan-container"
            style={{ display: "flex", flexDirection: "row" }}
          >
            <div className="arrows">»&nbsp;</div>
            <BookNowButton
              showIcon={false}
              title="Book MY FREE Consult!"
              href={ROUTES.CALENDLY_FOREVER_BALANCE_CONSULTATION}
              styleName={["pink", "normal-case"]}
            />
            <div className="arrows">&nbsp;«</div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "5rem",
          }}
        >
          <div
            style={{ height: "2px", backgroundColor: "black", width: "100%" }}
          />
        </div>
      </div>

      <div style={{ display: "flex", flexDirection: "column" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div>
            <StaticImage
              src={"../../../static/img/fb_testimonial_1.png"}
              alt="Testimonial: Thanks!!! I just wanted to say that this has been amazing. I have learned so much not just about food and exercise but about myself. You are a life changer! Your genuine concern about my health and success is truly amazing! I can tell that this is more than a business, you truly want everyone to succeed and be healthy!"
              loading="lazy"
            />
          </div>
          <div>
            <StaticImage
              src={"../../../static/img/fb_testimonial_2.png"}
              alt="Testimonial: So much room. Just bought these in February. Lol. (a client picture showing extra room in the waist for a pair of pants)"
              loading="lazy"
            />
          </div>
          <div>
            <StaticImage
              src={"../../../static/img/fb_testimonial_3.png"}
              alt="Testimonial: Just wanted to share with you my doctor's visit. I am down 20 pounds from last visit- all my blood work was great! He told me to keep doing what I am doing and next year we may be talking about getting me off some of my medicine! Forever Balanced works!!! Thank you!"
              loading="lazy"
            />
          </div>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div>
            <StaticImage
              src={"../../../static/img/fb_testimonial_4.png"}
              alt="Picture of a client with a measuring tape around her waist displaying the inches that she lost."
              loading="lazy"
            />
          </div>
          <div>
            <StaticImage
              src={"../../../static/img/fb_testimonial_5.png"}
              alt="Testimonial: My 12 weeks are up and I am so incredibly happy and encouraged by my results from Kristie's Forever Balanced Program. And they really aren't even results because I'm not done and I'm going to keep losing inches, continue with my new habits and grow healthier every day. I have never felt more confident and equipped to keep up this type of healthy, balanced lifestyle, and I've never been able to maintain this kind of consistency. As the program has come to an end, I have kept it up all on my own."
              loading="lazy"
            />
          </div>
          <div>
            <StaticImage
              src={"../../../static/img/fb_testimonial_6.png"}
              alt="Client with a measuring tape showing that she has lst over 14 inches"
              loading="lazy"
              height={800}
            />
          </div>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div>
            <StaticImage
              src={"../../../static/img/fb_testimonial_7.png"}
              alt="Testimonial: Kristie. THANK YOU! You have taught me so many things but the one I appreciate most today is that the kitchen is NOT a scary place and I can make GOOD TASTING FOOD while still thinking of my healthy goals. Cooking use to be so daunting but I spent half the day meal prepping stuff and I feel SOO good"
              loading="lazy"
            />
          </div>
          <div>
            <StaticImage
              src={"../../../static/img/fb_testimonial_8.png"}
              alt="Testimonial: Another great meeting. It is amazing how much I have grown in this journey!!! It truly is mindset and getting to know yourself. Thank you!!!"
              loading="lazy"
            />
          </div>
          <div>
            <StaticImage
              src={"../../../static/img/fb_testimonial_9.png"}
              alt="Testimonial: Face is unchubbing, my butt chin is making an appearance, almost 6 inches gone from my gut and a slight shadow of a bicep. No quick fix. No fad. Unlearning every diet I ever tried and learning healthy habits and a healthy relationship with food. And consistency at the gym. Kristie Lengel is amazing and you need her in your life if you want to see real achievable change."
              loading="lazy"
              height={600}
            />
          </div>
        </div>
      </div>

      <div className="dark-bg responsive-row">
        <div className="left fb-banner">
          <StaticImage
            src={"../../../static/img/kristie_arnold_working.png"}
            alt="Kristie with her bulldog, Arnold, trying to lick her face."
            loading="lazy"
          />
        </div>
        <div className="right fb-banner">
          <div className="fb-banner-text always-column">
            <div style={{ marginBottom: "2rem" }}>
              Let's chat and make sure Forever #BALANCE is a good fit! Book your
              FREE #BALANCE consultation today!
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                margin: 0,
              }}
            >
              <div className="arrows">»&nbsp;</div>
              <BookNowButton
                showIcon={false}
                title="Let's Do it!"
                href={ROUTES.CALENDLY_FOREVER_BALANCE_CONSULTATION}
                styleName={["pink", "normal-case"]}
              />
              <div className="arrows">&nbsp;«</div>
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <div>
          <h4
            className="has-text-centered is-size-2 has-text-weight-bold"
            style={{ marginTop: 20 }}
          >
            FAQ
          </h4>
        </div>
        <div
          style={{
            marginTop: 40,
            width: "75%",
            alignSelf: "center",
            marginBottom: 40,
          }}
        >
          <p style={{ fontWeight: 800 }}>
            1) What if I can't meet every single week?
          </p>
          <br />
          EVERYONE'S #balance journey is different! We've got work travel,
          vacations, family in town, sick days.....LIFE! We'll take it one week
          at a time and schedule appointments based on your availability and
          progress!
          <br />
          <br />
          <p style={{ fontWeight: 800 }}>
            2) What if I'm not looking to lose weight?
          </p>
          <br />
          Just because someone is a healthy weight doesn't mean they are
          actually HEALTHY right?! #BALANCE is less about weight loss, and more
          about building habits to get you feeling happy and healthy for a
          lifetime!
          <br />
          <br />
          <p style={{ fontWeight: 800 }}>
            3) What happens at the end of my 6 or 12 week program?
          </p>
          <br />
          CONTINUE!!! Your journey doesn't end once you complete the Forever
          #BALANCE program! There is no "end"!! You'll have tools and resources
          set up for you throughout the program that you can utilize to continue
          your journey, but if you ever need to check in, I'm always available
          for #BALANCE consultations!
          <br />
          <br />
          <p style={{ fontWeight: 800 }}>
            4) Will I need to purchase any equipment or diet products?
          </p>
          <br />
          The only thing you'll need is a set of dumbbells and a desire for
          change!
          <br />
          <br />
          <p style={{ fontWeight: 800 }}>
            5) What if I have questions or concerns between our Zoom
            appointments?
          </p>
          <br />
          You'll be able to contact me ANY TIME between appointments via Voxer
          or email! It's like having a "mini Kristie" in your pocket to support
          you every step of the way!
        </div>
      </div>

      <div
        className="dark-bg"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          fontSize: "48px",
          fontWeight: 600,
        }}
      >
        Change your MINDSET,
        <br />
        change your LIFE!
        <div
          style={{ width: "25%", backgroundColor: "#CD4F5F", height: "2px" }}
        />
      </div>
    </div>
  );
}

const ForeverBalancePage = () => (
  <Layout>
    <ForeverBalance />
  </Layout>
);

export default ForeverBalancePage;
