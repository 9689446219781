import * as React from "react";
import classNames from "classnames";
import { isDesktop } from "react-device-detect";
import { FaCalendarCheck } from "@react-icons/all-files/fa/FaCalendarCheck";

export const BookNowButton = ({
  href,
  title = "Book Now",
  showIcon = true,
  styleName = undefined,
}) => {
  return (
    <a
      className={classNames("book-now-button", styleName)}
      href={href}
      target="_blank"
      rel="noopener noreferrer"
    >
      {showIcon === true && <FaCalendarCheck style={{ marginRight: 2 }} />}{" "}
      {title}
    </a>
  );
};

const SignUpButton = ({ onClick }) => {
  return (
    <button className="sign-up-now" onClick={onClick}>
      Sign Up Now
    </button>
  );
};

export default function FoldBar({
  backgroundColor = "#9BABB4",
  showMobile = true,
  scriptText = null,
  regularText = null,
  showBookNow = false,
  onSignUpClick = null,
  bookNowHref = "https://calendly.com/kristielengel/talk-to-kristie",
  stacked = false,
}) {
  if (!showMobile && !isDesktop) {
    return null;
  }

  return (
    <div className="fold-bar" style={{ backgroundColor }}>
      <div className={classNames({ "text-bar": true, stacked })}>
        {!!scriptText && <div className="script-text">{scriptText}</div>}
        {!!regularText && <div className="regular-text">{regularText}</div>}
      </div>
      {showBookNow && <BookNowButton href={bookNowHref} />}
      {!!onSignUpClick && <SignUpButton onClick={onSignUpClick} />}
    </div>
  );
}
